
.btn {
    text-align: end;
    padding: 15px 50px;
    margin-bottom: 1rem;
}

button {
    display: inline-block;
    padding: 9px 25px;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    font-size: 1.2rem;
    color: white;
    font-family: OpenSans, sans-serif;
}

.btn .add {
    font-size: 1.4rem;
    padding: 7px 18px;
    background-color: rgba(187, 191, 195, 0.836)
}

.btn .add:hover {
    background-color: rgba(47, 47, 55, 0.681);
}

.btn .cancel {
    margin-bottom: 5px;
    padding: 9px 20px;
    background-color:rgb(137, 28, 57);
}

.btn .cancel:hover {
    background-color: rgb(72, 6, 19);
}

.btn .rem {
    padding: 10px 14px;
    background-color: rgba(187, 191, 195, 0.836)
}

.btn .rem:hover {
    background-color: rgba(47, 47, 55, 0.681);
}

.btn .add-page {
    width: 22rem;
    position: absolute;
    z-index: 1;
    right: 7rem;
}

.closet-clothing {
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-template-rows: repeat(10, minmax(0, 1fr));
    grid-gap: 5px;
    margin: 0 5rem;
}

.outfit {
    display:flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 2rem;
}

.closet-btn {
    background-color: rgb(114, 65, 65);
    margin: 1rem 0;
}

.closet-btn:hover {
    background-color: rgb(78, 37, 37);
}


/*--- Mobile Friendly ---*/

@media screen and (max-width:1000px) {
    .closet-clothing {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-template-rows: repeat(10, minmax(0, 1fr));
        margin: 0 3rem;
    }
}

@media screen and (max-width:500px) {
    .closet-clothing {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-template-rows: repeat(12, minmax(0, 1fr));
        margin: 0 2rem;
    }
}