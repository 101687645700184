
.clothingItem {
    display: inline-block;
    border-radius: 7px;
    background-color: rgba(244, 241, 236, 0.79);
    object-fit: contain;
}


.clothingItem .delete {
    padding: 16px 12px;
    background-color: rgb(137, 28, 57);
    font-size: 1rem;
    position: relative;
    right: -100px;
    top: -200px;
    line-height: 0;
}

.clothingItem .delete:hover {
    background-color: rgb(94, 19, 39);
}

.clothingItem svg {
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 35vw;
    object-fit: contain;
}

@media screen and (max-width: 500px) {
    .clothingItem .delete {
        font-size: 0.8rem;
        right: -17vw;
        top: -55vw;
        
    }
}

