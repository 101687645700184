
.add-tab {
    background-color:rgba(247, 247, 247, 0.985);
    border-radius: 30px;
    border: solid 1px rgba(101, 101, 101, 0.76);
    padding: 15px 15px;
    margin-top: 1rem;
}

.clothingSelect {
    width: 18rem;
    margin-left: auto;
    margin-top: auto;
    text-align: left;
}

.colorPicker {
    margin-left: auto;
}

.add-btn {
    margin-top: 15px;
    background-color: rgb(97, 97, 97);
}

.add-btn:hover {
    background-color: rgb(53, 53, 53);
}

/*--- Mobile Friendly ---*/

@media screen and (max-width:500px) {
    .add-tab {
        margin-left: 40vw;
        width: fit-content;
        
    }
}