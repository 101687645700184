
@font-face {
  font-family: OpenSans;
  font-style: Medium;
  font-weight: 450;
  src: url("./assets/fonts/OpenSans-VariableFont.ttf") format('truetype')
}

@font-face {
  font-family: Poppins;
  font-style: Black;
  font-weight: 900;
  src: url("./assets/fonts/Poppins-Black.ttf")
}

@font-face {
  font-family: PoppinsItalic;
  font-style: Medium;
  font-weight: 500;
  src: url("./assets/fonts/Poppins-Black.ttf")
}

body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: white;
}

.App {
  text-align: center;
  font-family: OpenSans, sans-serif;
  font-weight: 300;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1 {
  font-family: PoppinsItalic, sans-serif;
  font-size: 2.5rem;
  font-weight: 150;
  white-space: pre-line;
  color: rgb(10, 55, 72);
}

h2 {
  font-family: PoppinsItalic, sans-serif;
    font-size: 1.3rem;
    font-weight: 150;
    white-space: pre-line;
    color: rgb(10, 55, 72);
}

h3 {
  font-family: PoppinsItalic, sans-serif;
  font-size: 1.5rem;
  font-weight: 150;
  white-space: pre-line;
  color: rgb(10, 55, 72);
}

.footer {
  margin-top: 3rem;
  background-color: rgb(95, 95, 95);
  position: relative;
  bottom: 0;
  right: 0;
  width: 100vw;
  padding: 1rem 0;
}

.footer p {
  text-align: center;
  font-size: 1.2rem;
  color: white;
  margin-top: 1.3rem;
  margin-bottom: 0;
}

.footer .email {
  color: white;
  text-decoration: none;
  border-bottom: solid 1px;
}

.footer .email:hover{
  color: rgba(78, 118, 182, 0.951);
}

/*--- Mobile Friendly ---*/

@media screen and (max-width:500px) {
    h1 {
      font-size: 1.5rem;
    }

    h2 {
      font-size: 1.3rem;
    }

    h3 {
      font-size: 1.1rem;
    }

    p {
      font-size: 0.9rem;
      text-align: center;
    }

    .footer {
      margin-top: 10rem;
      padding-bottom: auto;
    }
}