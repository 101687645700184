
@font-face {
    font-family: OpenSans;
    font-style: normal;
    font-weight: 100;
    src: url("../assets/fonts/OpenSans-VariableFont.ttf") format('truetype')
}

header {
    font-family: PoppinItalic, sans-serif;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 4%;
    background-color: rgb(255, 255, 255);
    box-shadow: inset 0 -2px 0 0 rgba(148, 164, 175, 0.3);
}

.logo {
    cursor: pointer;
    max-width: 50vw;
}

.nav__items {
    list-style: none;
}

.nav__items li {
    display: inline-block;
    padding: 0px 20px;
}

.nav__items li a {
    color: rgb(10, 55, 72);
    transition: all 0.3s ease 0s;
    font-size: 1.2rem;
    text-decoration: none;
}

.nav__items li a:hover {
    color: rgba(78, 118, 182, 0.951);
    border-bottom: solid rgba(78, 118, 182, 0.951) ;
}

.auth-btns {
    margin-left: auto;
    padding: 0px 10px;
}

.auth-btns a {
    padding: 0px 10px;
    font-size: 1.1rem;
}

.auth-btns a button {
    padding: 9px 25px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    font-size: 1rem;
    font-family: OpenSans, sans-serif;
}

.signin-btn {
    background-color: rgba(187, 191, 195, 0.836)
}

.signin-btn:hover {
    background-color: rgba(96, 98, 103, 0.836)
}

.signup-btn {
    color:white;
    background-color: rgba(151, 36, 55, 0.982)
}

.signup-btn:hover {
    background-color: rgba(109, 13, 26, 0.982)
}

.contact {
    display: block;
    justify-content: center;
}

.contact_social-links {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-top: 0;
}

.contact-social-links a {
    text-decoration: none;
}

.contact_social-logo {
    height: 2.5rem;
    margin: 10px;
}

@media(max-width: 500px) {

    .logo {
        margin-top: 25px;
    }

    header {
        display:block;
    }
    .nav__items {
        padding: 0;
        margin-top: 30px;
        margin-bottom: 5px;
    }

    .nav__items li {
        padding-right: 20px;
        padding-left: 0;
    }

    .nav__items li a {
        color: rgb(10, 55, 72);
        transition: all 0.3s ease 0s;
        font-size: 1rem;
        text-decoration: none;
    }

    .auth-btns {
        position:absolute;
        right: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        padding: 10px;
    }

    .auth-btns a {
        padding: 0;
    }

    .auth-btns a button {
        padding: 5px 17px;
        border: none;
        border-radius: 50px;
        transition: all 0.3s ease 0s;
        font-size: 0.8rem;
        margin: 5px;
        
    }
}

